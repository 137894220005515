.aboutmaindiuvdivkkkk {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 40px;
}

.aboutquestiondivsss {
    width: 100%;
    max-width: 80%;
    min-width: 80%;
    margin-left: 50px;
}

.abtusttxtppp {
    font: 600 30px/35px var(--heading--font);
    color: #0e2537;
    text-align: center;
    margin-bottom: 20px;
}

.abouttqisyytsttxpp {
    font: 700 35px / 35px var(--heading--font);
    color: #0e2537;
    margin-bottom: 10px;
}

.abouttanswerrttsttxpp {
    font: 500 30px / 35px var(--heading--font);
    color: #0e2537;
}

.abouttabotatdivp {
    margin-bottom: 25px
}

.abouttabotatdivp:last-child {
    margin-bottom: 0px;
}

.globaldefenseiaboytmgmainfortch {
    position: relative;
    z-index: 9;
    transform: translateY(50%);
    right: 7px;
    top: 14px;
}

@media(max-width:1399px) {
    .aboutquestiondivsss {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
        margin-left: 34px;
    }

    .aboutmaindiuvdivkkkk {
        column-gap: 30px;
    }

    .abouttqisyytsttxpp {
        font: 700 30px / 35px var(--heading--font);
    }

}


@media(max-width:1260px) {
    .aboutquestiondivsss {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
        margin-left: 34px;
    }

    .aboutmaindiuvdivkkkk {
        column-gap: 15px;
    }

    .abouttqisyytsttxpp {
        font: 700 28px / 32px var(--heading--font);
    }

    .abouttanswerrttsttxpp {
        font: 500 28px / 32px var(--heading--font);
        color: #0e2537;
    }

    .globaldefenseiaboytmgmainfortch {
        top: 8px;
    }

}

@media (max-width: 1199px) {
    .abouttanswerrttsttxpp {
        font: 500 25px / 30px var(--heading--font);
    }

    .abouttqisyytsttxpp {
        font: 700 25px / 30px var(--heading--font);
    }

    .globaldefenseiaboytmgmainfortch {
        right: 37px;
        top: 2px;
    }
}


@media (max-width: 991px) {
    .aboutmaindiuvdivkkkk {
        flex-wrap: wrap;
    }

    .globaldefenseiaboytmgmainfortch {
        left: 50%;
        top: 54px;
        transform: translateX(-50%);
    }

    .aboutquestiondivsss {
        width: 100%;
        max-width: 100%;
        margin-left: 5px;
    }

    .aboutmaindiuvdivkkkk {
        column-gap: 69px;
    }
}

@media (max-width: 776px) {
    .abouttanswerrttsttxpp {
        font: 500 20px / 22px var(--heading--font);
    }

    .abouttqisyytsttxpp {
        font: 700 25px / 30px var(--heading--font);
    }

    .globaldefenseiaboytmgmainfortch {
        /* right: 49px; */
        top: 59px;
    }

}

@media (max-width: 576px) {
    .abouttanswerrttsttxpp {
        font: 500 18px / 22px var(--heading--font);
    }

    .abouttqisyytsttxpp {
        font: 700 20px / 25px var(--heading--font);
    }

    .globaldefenseiaboytmgmainfortch {
        /* right: 49px; */
        top: 48px;
    }
    .abtusttxtppp {
        font: 600 25px / 30px var(--heading--font);
    }

}