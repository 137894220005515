.registrationsection{
    width: 100%;
    height: auto;
    padding: 40px 0px;
}
.registrationconentall{
    width: 100%;
}
.crtyiyyacnttxtp{
    text-align: center;
    color: #7c7979;
    font: 400 25px/30px var(--heading--font);
    letter-spacing: 1px;
}
.registarytonfrmdiv{
    width: 100%;
    max-width: 40%;
    margin: 0 auto;
    padding: 40px 0px;
}
.regitartionfrminptmain{
    width: 100%;
    position: relative;
    padding-bottom: 20px;
}
.regitartionfrminpt{
    width: 100%;
    border: 1px solid #0e2537;
    padding: 10px 15px;
    color: #0e2537;
    float: 400 16px/20px var( --para--font);
    border-radius: 5px;
}
.passwordshowhideicondiv i{
   position: absolute;
   top: 14px;
   right: 20px;
   color: #0e2537;
   font-size: 20px;
}
.regitartionfrminptcheckdiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding-bottom: 20px;
}
.regitartionfrmchecinpt{
    width: 100%;
    max-width: 15px;
    min-width: 15px;
    height: 15px;
}
.regitartionfrmchecinpttxtp{
    color: #7c7979;
    font: 400 17px / 16px var(--para--font);
    margin-left: 5px;
}
.regitartionfrmcheckbox{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 5px;
}
.regitsttrionaccpttxtpaccept{
    font: 400 17px/17px var(--para--font);
    color: #7c7979;
}
.registerbtn{
    border: none;
    background-color: #0e2537;
    color: #fff;
    font: 400 17px / 22px var(--para--font);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 32px;
}
.frgtytpasstxtp{
    color: #7c7979;
    font: 400 16px/20px var(--para--font);
    margin-top: 5px;
}
.cliclforgtpaswrd{
    color: #0d6efd;
    font: 400 16px/20px var(--para--font);
    text-decoration: none;
}
.lgninbtn{
    border: none;
    background-color: #0e2537;
    color: #fff;
    font: 400 17px / 22px var(--para--font);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 32px;  
}
.SignUpbtn{
    border: none;
    background-color: #0e2537;
    color: #fff;
    font: 400 17px / 22px var(--para--font);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 32px;  
}

.SignUpbtnsaifeacctnbbtn{
    margin: 50px auto;
}
.saifeacntncttxtpp{
    text-align: center;
    font: 400 14px/14px var(--para--font);
    color: #7c7979;
    margin-bottom: 5px;
}