.offnedersectionmain{
    width: 100%;
    height: auto;
    padding: 50px 0px 50px 0px;

}
.currentandtypesrchntndiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
    margin-bottom: 15px;
}
.datacurrenttxtp{
    color: #1fb4d2;
    font: 400 18px/22px var(--para--font);

}
.typesrchbtn{
    border: 1px solid #0e2537;
    padding: 6px 15px;
    text-align: left;
    font: 400 16px / 20px var(--para--font);
    background-color: transparent;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}
.typesrchbtn:focus{
    outline: 0;
    border: 1px solid #0e2537;
}
.tableheadtxtpp{
    text-align: center;
    color: #0e2537;
    font: 700 25px/30px var(--heading--font);

}
.tabledatallforaioffender{
    width: 100%;
    margin-top: 20px;
}
.offenderdatatablediv{
    width: 100%;
    border-collapse: separate;
}
.offenderdatatableheaddiv{
    width: 100%;
}
.offenderdatatableheadtd{
    font: 700 20px / 25px var(--heading--font);
    color: #0e2537;
    text-align: center;
    padding-bottom: 20px;
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}
.offenderdatatableheadtd:first-child{
   text-align: left;
}
.offenderdatatablebodydiv{
    width: 100%;
}
.offenderdatatablebodytd{
    text-align: center;
    color: #1fb4d2;
    font: 400 18px/24px var(--para--font);
    padding-bottom: 15px;
    text-decoration: underline;
   

}
.offenderdatatablebodytd:first-child{
    text-align: left;
    color: #0e2537;
    font: 400 27px/30px var(--para--font);
    padding-bottom: 15px; 
    text-decoration: none;
}
.offenderdatatablebodytd:nth-child(3){
    text-decoration: none;
}
.offenderdatatablebodytd:nth-child(5){
    text-decoration: none;
}
.arrowupglobaldiv {
    vertical-align: super;
    margin-left: 10px;
}
.arrowupglobaldiv i{
    color: green;
    font-size: 20px;
    vertical-align: middle;
}
.arrowupoffenderdiv{
    vertical-align: sub;
    margin-left: 10px;
}
.arrowupoffenderdiv i{
    color: red;
    font-size: 20px;
    vertical-align: middle;
}
.offenderdatatablebodytd:last-child{
    text-align: center;
    color: #1fb4d2;
    font: 400 18px/24px var(--para--font);
    padding-bottom: 15px; 
    text-decoration: none;
}
.offenderdatatablebodytd:nth-child(6){
    text-align: center;
    color: #1fb4d2;
    font: 400 18px/24px var(--para--font);
    padding-bottom: 15px; 
    text-decoration: underline; 
}
.fisrttwobtndiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}
.dashboardbtn{
    background-color: #1fb4d2;
    color: #fff;
    float: 400 15px/20px var(--para--font);
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
}
.dashboardbtn:hover{
    background-color: #1fb4d2;
    color: #fff;  
}
.typeristkbtn{
    background-color: transparent;
    color: #1fb4d2;
    float: 400 15px/20px var(--para--font);
    padding: 5px 15px;
    border: 1px solid #1fb4d2;
    border-radius: 5px; 
    color: #1fb4d2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}
.typesrchbtndiv{
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}
.typeristkbtn:focus{
    border: 1px solid #1fb4d2;
    outline: 0; 
}
.typeristkbtn::placeholder{
    color: #1fb4d2;
}
.typeristkbtndiv{
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}
.fisrtallbtndivspcl{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.riskdatatableheadtd{
    font: 700 20px / 25px var(--heading--font);
    color: #0e2537;
    text-align: left;
    padding-bottom: 20px;
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}
.riskdatatableheadtd:nth-child(2){
    text-align: center; 
}

.riskdatatableheadtd:last-child{
    text-align: center; 
}

.riskdatadatatablebodytd {
    text-align: left;
    color: #0e2537;
    font: 400 18px / 24px var(--para--font);
    padding-bottom: 10px;
    text-decoration: none;
    vertical-align: top;
}
.riskdatadatatablebodytd:nth-child(2){
    text-align: center; 
}

.riskdatadatatablebodytd:last-child{
    text-align: center; 
}
.riskcategorydatatablediv{
    width: 100%;
    max-width: 65%;
    min-width: 65%;
    border-collapse: collapse;  
}
.policyvilationdatatablediv{
    width: 100%;
    max-width: 85%;
    min-width: 85%; 
    border-collapse: collapse;  
}


.policyviolationdatadatatablebodytd{
    text-align: left;
    color: #0e2537;
    font: 400 18px / 24px var(--para--font);
    padding-bottom: 10px;
    text-decoration: none;
    white-space: normal;
    word-break: break-all;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
    vertical-align: top;
}
.policyviolationdatadatatablebodytd:nth-child(2){
    text-align: center; 
    vertical-align: top;
}

.policyviolationdatadatatablebodytd:nth-child(3){
    text-align: center; 
    vertical-align: top;
}

.policyvilationdatatableheadtd{
    font: 700 20px / 25px var(--heading--font);
    color: #0e2537;
    text-align: left;
    padding-bottom: 20px;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}
.policyvilationdatatableheadtd:nth-child(2){
    text-align: center; 
}

.policyvilationdatatableheadtd:nth-child(3){
    text-align: center; 
}
.lawinfractiondatatablediv{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-collapse: collapse;  
}


.lawinfractiondatatableheadtd{
    font: 700 20px / 25px var(--heading--font);
    color: #0e2537;
    text-align: left;
    padding-bottom: 20px;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}
.lawinfractiondatatableheadtd:nth-child(2){
    text-align: center; 
}

.lawinfractiondatatableheadtd:nth-child(3){
    text-align: center; 
}

.lawinfractiondatatableheadtd:nth-child(4){
    text-align: center; 
}
.lawinfractiondatatableheadtd:nth-child(5){
    text-align: center; 
}

.lawinfractiondatadatatablebodytd{
    text-align: left;
    color: #0e2537;
    font: 400 18px / 26px var(--para--font);
    padding-bottom: 10px;
    text-decoration: none;
    white-space: normal;
    word-break: break-all;
    vertical-align: top;
    /* width: 100%;
    max-width: 20%;
    min-width: 20%;
    vertical-align: top; */
}
.lawinfractiondatadatatablebodytd:nth-child(2){
    text-align: center; 
    vertical-align: top;
}

.lawinfractiondatadatatablebodytd:nth-child(3){
    text-align: center; 
    vertical-align: top;
}
.lawinfractiondatadatatablebodytd:nth-child(4){
    text-align: center; 
    vertical-align: top;
}
.lawinfractiondatadatatablebodytd:nth-child(5){
    text-align: center; 
    vertical-align: top;
}

@media(max-width:1199px){
    .offenderdatatablebodytd:first-child{
        font: 400 20px / 25px var(--para--font);
    }
    .offenderdatatableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }
    .riskcategorydatatablediv {
        width: 100%;
        max-width: 75%;
        min-width: 75%;
        border-collapse: collapse;
    }
    .policyvilationdatatablediv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border-collapse: collapse;
    }
    .typeristkbtndiv {
        width: 100%;
        max-width: 40%;
        min-width: 40%;
    }
    .riskdatatableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }
    .policyvilationdatatableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }
    .lawinfractiondatatableheadtd {
        font: 700 18px / 22px var(--heading--font);
       
    }
   
  
}

@media(max-width:991px){
    .tableheadtxtpp {
        font: 700 20px / 25px var(--heading--font);
        margin-top: 10px;
    }
    .tabledatallforaioffender {
        width: 100%;
        margin-top: 14px;
        width: 100%;
        overflow-x: scroll;
    }
    .offenderdatatablediv{
        white-space: nowrap;
    }
    .offenderdatatableheadtd:nth-child(2){
        padding-left: 40px;
    }
    .offenderdatatableheadtd:nth-child(3){
        padding-left: 40px;
    }
    .offenderdatatableheadtd:nth-child(4){
        padding-left: 40px;
    }
    .offenderdatatableheadtd:nth-child(5){
        padding-left: 40px;
    }
    .datacurrenttxtp {
        font: 400 16px / 18px var(--para--font);
    }
    .offnedersectionmain {
        padding: 40px 0px 40px 0px;
    }
    .riskcategorydatatablediv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .lawinfractiondatatablediv{
         white-space: nowrap;
    }
    .lawinfractiondatatableheadtd:nth-child(2){
        padding-left: 80px;
    }
    .lawinfractiondatatableheadtd:nth-child(3){
        padding-left: 80px;
    }
    .lawinfractiondatatableheadtd:nth-child(4){
        padding-left: 50px;
    }
    .lawinfractiondatatableheadtd:nth-child(5){
        padding-left: 80px;
    }
    .tabledatallforaioffender{
        overflow-x: scroll;
        width: 100%;
    }
    .lawinfractiondatadatatablebodytd:nth-child(2) {
      
        padding-left: 50px;
    }
    .lawinfractiondatadatatablebodytd:nth-child(3) {
      
        padding-left: 50px;
    }
    .lawinfractiondatadatatablebodytd:nth-child(4) {
      
        padding-left: 50px;
    }

    .lawinfractiondatadatatablebodytd:nth-child(5) {
      
        padding-left: 50px;
    }
    .offenderdatatablebodytd {
    
        padding-left: 40px;
    }
    .offenderdatatablebodytd:first-child {
        font: 400 20px / 25px var(--para--font);
        text-align: left;
        padding-left: 0px;
    }
    .offenderdatatableheadtd:nth-child(6) {
        padding-left: 40px;
    }
}

@media(max-width:767px){
    .offenderdatatableheadtd {
        font: 700 15px / 17px var(--heading--font);
    }
    .typesrchbtndiv {
        width: 100%;
        max-width: 52%;
        min-width: 52%;
    }
    .datacurrenttxtp {
        font: 400 15px / 20px var(--para--font);
        margin-bottom: 10px;
    }
    .tableheadtxtpp {
        font: 700 18px / 22px var(--heading--font);
        margin-top: 10px;
    }
    
     .fisrtallbtndivspcl{
        flex-wrap: wrap;
     }
     .fisrttwobtndiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 25px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        order: 2;
    }
    .typeristkbtndiv {
        width: 100%;
        max-width: 60%;
        min-width: 60%;
    }
    .policyvilationdatatablediv{
            white-space: nowrap;
    }
    .policyvilationdatatableheadtd:nth-child(2) {
        padding-left: 30px;
    }
    .policyvilationdatatableheadtd:nth-child(3) {
        padding-left: 30px;
    }
    .policyvilationdatatableheadtd:nth-child(4) {
        padding-left: 30px;
    }
    .policyviolationdatadatatablebodytd:nth-child(4) {
        text-align: center;
        vertical-align: top;
    }
    .offenderdatatablebodytd:first-child {
        font: 400 18px / 22px var(--para--font);
    }
}
@media(max-width:576px){
    .currentandtypesrchntndiv{
        flex-wrap: wrap;
    }
    .dashboardbtn {
        background-color: #1fb4d2;
        color: #fff;
        float: 400 12px / 17px var(--para--font);
        padding: 5px 11px;
    }
    .typesrchbtndiv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-top: 6px;
    }
    .offnedersectionmain {
        padding: 20px 0px 20px 0px;
    }
    .fisrttwobtndiv{
        flex-wrap: wrap;
    }
    .typeristkbtndiv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-top: 10px;
    }
    .riskdatatableheadtd {
        font: 700 15px / 17px var(--heading--font);
    }
    .riskdatadatatablebodytd {
        font: 400 15px / 18px var(--para--font);
    }
    .riskcategorydatatablediv{
       white-space: nowrap;
    }
    .riskdatatableheadtd:nth-child(2) {
        padding-left: 30px;
    }
    .riskdatatableheadtd:nth-child(3) {
        padding-left: 30px;
    }
    .policyviolationdatadatatablebodytd {
        font: 400 15px / 18px var(--para--font);
    }
    .policyvilationdatatableheadtd {
        font: 700 15px / 17px var(--heading--font);
    }
    .lawinfractiondatatableheadtd {
        font: 700 15px / 17px var(--heading--font);
    }
    .lawinfractiondatadatatablebodytd {
        font: 400 15px / 18px var(--para--font);
    }

}

/* @media(max-width:480px){
    .policyviolationdatadatatablebodytd {
        font: 400 16px / 20px var(--para--font);
    }
} */