.homebanner {
    width: 100%;
    height: auto;
    padding: 50px 0px 0px 0px;
}

.globaldefenseimg {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    height: 400px;
    margin: 0 auto;

}

.globaldefenseimg img {
    width: 100%;
    height: 100%;
}

.homepagespcllogo {
    position: relative;
    top: 35px;
    z-index: 8;
}

/* responsive start */

@media(max-width:1199px) {
    .globaldefenseimg {
        width: 100%;
        max-width: 350px;
        min-width: 350px;
        height: 350px;
        margin: 0 auto;
    }
}


@media(max-width:991px) {
    .globaldefenseimg {
        width: 100%;
        max-width: 300px;
        min-width: 300px;
        height: 300px;
        margin: 0 auto;
    }
  
    .homepagespcllogo {
        top: 27px;
    }
    .homebanner {
        padding: 40px 0px 0px 0px;
    }
   
}

@media(max-width:576px){
    .globaldefenseimg {
        width: 100%;
        max-width: 250px;
        min-width: 250px;
        height: 250px;
        margin: 0 auto;
    }
    .homebanner {
        padding: 30px 0px 0px 0px;
    }
    .homepagespcllogo {
        top: 20px;
    }
}

/* responsive end */