.aihumintymainsection{
    width: 100%;
    height: auto;
}
.aihumintymaintybg{
    background-image: url(../../Images/producttour_bg.png);
    width: 100%;
    height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.secutringtxtpp{
    text-align: center;
    color: #ccc;
    font: 500 20px/22px var(--heading--font);
    padding-top: 20px;
}
.welcomesaifetxtp{
    text-align: center;
    padding: 90px 0px;
    color: #0e2537;
    font: 300 40px/45px var(--para--font);
    letter-spacing: 1px;
}
.techpogocobtn{
   border: 1px solid #0e2537;
   padding: 8px 30px;
   background-color: transparent;
   color: #0e2537;
   font: 400 18px/22px var(--para--font);
   border-radius: 40px;
   text-decoration: none;
}
.techpogocobtnmain{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 210px;
}

@media(max-width:1199px){
    .techpogocobtnmain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 120px;
    }
}


@media(max-width:991px){
    .techpogocobtnmain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 40px;
    }
}

@media(max-width:767px){
    .techpogocobtnmain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 15px;
    }
    .techpogocobtn {
        border: 1px solid #0e2537;
        padding: 8px 20px;
        background-color: transparent;
        color: #0e2537;
        font: 400 16px / 18px var(--para--font);
        border-radius: 40px;
        text-decoration: none;
    }
}

@media(max-width:576px){
    .techpogocobtnmain {
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 20px;
        row-gap: 20px;
        padding: 20px 15px;
    }
    .welcomesaifetxtp {
        text-align: center;
        padding: 50px 0px;
        color: #0e2537;
        font: 300 31px / 37px var(--para--font);
        letter-spacing: 1px;
    }
    .techpogocobtn {
        border: 1px solid #0e2537;
        /* padding: 12px 70px; */
        width: 100%;
        max-width: 47%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .welcomesaifetxtp {
        text-align: center;
        padding: 20px 0px;
        color: #0e2537;
        font: 300 25px / 30px var(--para--font);
        letter-spacing: 1px;
    }
}