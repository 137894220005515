* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  margin: 0px;
  padding: 0px;
}

.alllinddivlitxtpp.active {
  text-decoration: underline;
}

:root {
  --heading--font: "Open Sans", sans-serif;
  --para--font: "Roboto", sans-serif;
}

.cust-container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

/* Header Section design Start */
.headermain {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px 0px;
}
.alllinddivlitxtpp.active-link{
   text-decoration: underline;
}

.logolnkimage {
  width: 100%;
  max-width: 150px;
  min-width: 150px;
}

.logolnkimage img {
  width: 100%;
  height: 100%;
}

.logo-menudivmain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.alllinddivflx {
  display: flex;
  flex-basis: auto;
  align-items: center;
}

.alllinddivflxul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.alllinddivflxli {
  margin: 0 5px;
  list-style-type: none;
}

.alllinddivlitxtpp {
  color: #0e2537;
  padding: 0 14px;
  position: relative;
  text-decoration: none;
  font: 400 18px/22px var(--para--font);
}

.allnewlinkdivflx {
  display: flex;
  justify-content: center;
  align-items: center;
}

.allnewlinkdivul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.translatecustom {
  border: none !important;
  border-radius: 30px;
  max-width: 200px;
  padding: 2px;
  font: 500 18px/22px var(--para--font);
  color: #0e2537;
}

.translatecustom:focus {
  border: none;
  outline: 0;
}

.allnewlinkdivli {
  margin: 0 10px 0px 30px;
  list-style-type: none;
  position: relative;
}

.borderrightcustom {
  background-color: #3c3c3b;
  height: 35px;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
}

.lgnbtn {
  border: none;
  font: 500 18px/22px var(--para--font);
  background-color: transparent;
  color: #0e2537;
  text-decoration: none;
}

.lgnbtn:focus {
  border: none;
  outline: 0;
}

.sgnbtn {
  border: 1px solid #0e2537;
  border-radius: 40px;
  padding: 6px 15px;
  font: 500 18px/22px var(--para--font);
  background-color: transparent;
  color: #0e2537;
  text-decoration: none;
}

.sgnbtn {
  border: 1px solid #0e2537;
  outline: 0;
}

.offwnderbtn {
  border: 1px solid #0e2537;
  padding: 6px 15px;
  text-align: center;
  font: 400 18px/22px var(--para--font);
  background-color: transparent;
  border-radius: 5px;
  text-decoration: none;
  color: #0e2537;
}

.offwnderbtn:focus {
  border: 1px solid #0e2537;
  outline: 0;
}

.headerspcbtnli {
  margin: 0 10px 0px 10px;
  list-style-type: none;
  position: relative;
}

.resbar i {
  font-size: 24px;
  color: #0e2537;
}

.resbar {
  vertical-align: middle;
  align-self: center;
  display: none;
}

/* Header Section design End */

/* Landing Page Header Start */
.landingpageheader{
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px 0px;
}
.logolnkimagelanfdibg{
  width: 100%;
  max-width: 550px;
  min-width: 550px;
  height: 94px;
}
.logolnkimagelanfdibg img{
  width: 100%;
  height: 100%;
}
.alllinddivflxlandigf{
  display: flex;
  flex-basis: auto;
  align-items: center;
}
/* Landing Page Header End */

/* Sidebar Section Start */
.sidebarmain{
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  height: 100vh;
  transition: .5s all ease-in-out;
  
}
.sidebarmain.actv{
  left: 0px;
  transition: .5s all ease-in-out;
}
.sidebarmaincontent{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #ccc;
}
.sidebarlogomain{
  width: 100%;
  max-width: 140px;
  min-width: 140px;
  height: 52px;
}
.sidebarlogomain img{
  width: 100%;
  height: 100%;
}
.circlecressdiv i{
  font-size: 25px;
  color: #0e2537;
}
.sidebarmenudiv{
  width: 100%;
  max-width: 100%;
}
.sidebarmenudivli{
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #0e2537;

}

.sidebarmenudivlitxtpp{
  color: #0e2537;
  position: relative;
  text-decoration: none;
  font: 400 18px / 22px var(--para--font); 
}
.sidebarmenudivlitxtpp.active{
  text-decoration: underline;
}

/* Sidebar Section End */

/* Footer section design start */
.foootermain {
  width: 100%;
  height: auto;
  background-color: #0e2537;
  padding: 50px 0px 10px 0px;
  position: relative;
}

.fottercustcontainer {
  width: 100%;
  padding: 0px 80px 0px 100px;
}

.footerlogosocilalinkflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fotterbrdrmain {
  position: relative;
}

.fotterbrdrmainspan {
  background-color: #ffffff;
  height: 20px;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
}

.fotterbrdrlinksocialdiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.footersocilaiocndiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}

.footersocilaiocndivli {
  list-style-type: none;
}

.footersocilaiocnsoclnlk {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
}

.footersocilaiocnsoclnlk i {
  color: #0e2537;
  font-size: 20px;
}

.fotterpagelinkdivul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fotterpagelinkdivli {
  margin: 0px 10px;
}

.fotterpagelinkdivlnlttxp {
  color: #1fb4d2;
  font: 400 18px/22px var(--para--font);
  text-decoration: none;
}

.fotterpagelinkdivlnlttxp:hover {
  color: #1fb4d2;
}

.fotterpagelinkdivulmain {
  margin-top: 40px;
  margin-bottom: 25px;
}

.saifespclllnik {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.saifespclllniktxtp {
  color: #fff;
  font: 400 15px/20px var(--para--font);
  text-decoration: none;
}

.saifespclllniktxtp:hover {
  color: #fff;
}

.saifespclllnik {
  margin-top: 20px;
}

.privacycopyrightdiv {
  margin-top: 20px;
}

.privacycopyrightxtp {
  color: #fff;
  font: 300 15px/20px var(--para--font);
  margin-bottom: 5px;
}
.fotterpagelinkdivulresponsive{
  display: none;
}

.privacycopyrightxtplnk {
  color: #fff;
  font: 300 15px/20px var(--para--font);
  margin-bottom: 5px;
  text-decoration: none;
}

/* Footer section design End  */


/* responsive start */
@media(max-width:1499px){
.logolnkimage {
  width: 100%;
  max-width: 140px;
  min-width: 140px;
  height: auto;
}

.alllinddivlitxtpp {
  padding: 0px 10px;
}

.borderrightcustom {
  right: -16px;
}

.allnewlinkdivli {
  margin: 0 10px 0px 20px;
}

.alllinddivlitxtpp {
  font: 400 17px / 20px var(--para--font);
}

.translatecustom {
  font: 400 17px / 20px var(--para--font);
}

.lgnbtn {
  font: 400 17px / 20px var(--para--font);
}

.sgnbtn {
  font: 500 17px / 22px var(--para--font);
}

.offwnderbtn {
  font: 400 17px / 22px var(--para--font);
}

.cust-container {
  width: 100%;
  max-width: 92%;
  margin: 0 auto;
}
}

@media(max-width:1280px) {
  .cust-container {
    width: 100%;
    max-width: 98%;
    margin: 0 auto;
  }

  .alllinddivlitxtpp {
    font: 400 15px / 20px var(--para--font);
  }

  .translatecustom {
    font: 400 15px / 20px var(--para--font);
  }

  .lgnbtn {
    font: 400 15px / 19px var(--para--font);
  }

  .sgnbtn {
    font: 500 15px / 19px var(--para--font);
  }

  .offwnderbtn {
    font: 400 15px / 19px var(--para--font);
  }

  .fottercustcontainer {
    width: 100%;
    padding: 0px 50px 0px 80px;
  }

  .fotterpagelinkdivlnlttxp {
    color: #1fb4d2;
    font: 400 15px / 19px var(--para--font);
    text-decoration: none;
  }
}

@media(max-width:1199px) {
  .logolnkimage {
    width: 100%;
    max-width: 120px;
    min-width: 120px;
    height: auto;
  }

  .alllinddivlitxtpp {
    padding: 0px 8px;
  }

  .borderrightcustom {
    right: -12px;
  }

  .allnewlinkdivli {
    margin: 0 10px 0px 12px;
  }

  .lgnbtn {
    font: 400 14px / 18px var(--para--font);
  }

  .sgnbtn {
    font: 500 14px / 18px var(--para--font);
    padding: 5px 10px;
  }

  .offwnderbtn {
    font: 400 14px / 18px var(--para--font);
    padding: 4px 10px;
  }

}

@media(max-width:991px) {
  .alllinddivflx {
    display: none;
  }

  .logolnkimage {
    width: 100%;
    max-width: 135px;
    min-width: 135px;
    height: auto;
  }
  .logolnkimagelanfdibg {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    height: 65px;
}
  .privacycopyrightdiv {
    margin-left: 10px;
  }

  .fottercustcontainer {
    width: 100%;
    padding: 0px 20px 0px 20px;
  }

  .footersocilaiocnsoclnlk {
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;

  }

  .footersocilaiocnsoclnlk i {
    font-size: 15px;
  }

  .fotterpagelinkdivlnlttxp {
    font: 400 13px / 15px var(--para--font);
  }

  .saifespclllniktxtp {
    font: 400 13px / 19px var(--para--font);
  }

  .privacycopyrightxtp {
    font: 300 13px / 17px var(--para--font);
  }

  .fotterpagelinkdivli {
    margin: 0px 8px;
  }

  .fotterpagelinkdivulresponsive {
    display: none;
  }
  .resbar {
    vertical-align: middle;
    align-self: center;
    display: block;
  }
  
}

@media(max-width:576px) {
  .fotterpagelinkdivul {
    display: none;
  }

  .fotterpagelinkdivulresponsive {
    display: block;
  }

  .fotterpagelinkdivulmain {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fotterpagelinkdivli {
    margin: 6px 9px;
  }

  .fotterpagelinkdivlnlttxp {
    font: 400 16px / 18px var(--para--font);
  }

  .saifespclllnik {
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 11px;
  }

  .saifespclllniktxtp {
    font: 400 16px / 18px var(--para--font);
  }

  .privacycopyrightxtp {
    font: 300 13px / 17px var(--para--font);
    margin-top: 10px;
    text-align: center;
  }

  .privacycopyrightdiv {
    margin-top: 9px;
  }

  .privacycopyrightxtplnk {
    color: #fff;
    font: 400 16px / 20px var(--para--font);
    margin-bottom: 5px;
    text-decoration: none;
  }
  .logolnkimagelanfdibg {
    width: 100%;
    max-width: 160px;
    min-width: 160px;
    height: 50px;
  }
  .sidebarmain {
    width: 100%;
    max-width: 280px;
    min-width: 280px;
}


}
@media(max-width:480px){
  .logolnkimagelanfdibg {
    width: 100%;
    max-width: 145px;
    min-width: 145px;
    height: 37px;
}
}

@media(max-width:420px) {
  .fotterpagelinkdivulmain {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .logolnkimagelanfdibg {
    width: 100%;
    max-width: 200px;
    min-width: 200px;
}
  .fotterpagelinkdivlnlttxp {
    font: 400 14px / 16px var(--para--font);
  }

  .saifespclllniktxtp {
    font: 400 14px / 16px var(--para--font);
  }
  .sidebarmain {
    width: 100%;
    max-width: 250px;
    min-width: 250px;
}
.sidebarlogomain {
  width: 100%;
  max-width: 125px;
  min-width: 125px;
  height: 43px;
}

  .privacycopyrightxtplnk {
    font: 400 14px / 16px var(--para--font);
  }

  .logolnkimage {
    width: 100%;
    max-width: 125px;
    min-width: 125px;
    height: auto;
  }

  .fottercustcontainer {
    padding: 0px 15px 0px 15px;
  }
  .logolnkimagelanfdibg {
    width: 100%;
    max-width: 154px;
    min-width: 154px;
    height: 42px;
}
}

@media(max-width:380px){}
.logolnkimagelanfdibg {
  width: 100%;
  max-width: 122px;
  min-width: 120px;
  height: 34px;
}


/* responsive end */