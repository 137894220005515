.policyandlowmakertablediv{
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
}
.policytbaledivmain{
    width: 100%;
    max-width: 48%;
    min-width:48%
}
.lowmakerdivtbaledivmain{
    width: 100%;
    max-width: 48%;
    min-width:48%
}
.governencepolicytablediv{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-collapse: collapse;
}
.governencepolicytableheadtd{
    font: 700 22px / 28px var(--heading--font);
    color: #0e2537;
    padding-bottom: 20px;
}
.governencepolicytableheadtd:nth-child(2){
    text-align: center;
}

.governencepolicytableheadtd:nth-child(3){
    text-align: center;
}
.borderparticipant{
    height: auto;
    background-color: #0e2537;
    width: 1px;
    margin-left: 15px;
    margin-right: 30px;
    padding: 0.7px;
}
.giovernencretesypeterchbtnrchdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
}
@media(max-width:1199px){
    .governencepolicytableheadtd {
        font: 700 18px / 22px var(--heading--font);
        color: #0e2537;
        padding-bottom: 6px;
    }
}

@media(max-width:1084px){
    .borderparticipant {
        height: auto;
        background-color: #0e2537;
        width: 1px;
        margin-left: 15px;
        margin-right: 30px;
        padding: 0.7px;
    }
}

@media(max-width:991px){
    .giovernencretesypeterchbtnrchdivflx {
        flex-wrap: wrap;
    }
    .policyandlowmakertablediv{
        width: 100%;
        overflow-x: scroll;
    }
    .lowmakerdivtbaledivmain {
        width: 100%;
        overflow-x: scroll;
    }
    .policytbaledivmain {
        width: 100%;
        max-width: 65%;
        min-width: 65%;
    }
    .lowmakerdivtbaledivmain {
        width: 100%;
        max-width: 65%;
        min-width: 65%;
    }
    .borderparticipant{
        height: auto;
        background-color: #0e2537;
        width: 1px;
        margin-left: 15px;
        margin-right: 30px;
        padding: 0.7px;
    }
    .governencepolicytablediv{
        width: 100%;
        /* white-space: nowrap; */
    }
}
@media(max-width:767px){
    .policytbaledivmain {
        width: 100%;
        max-width: 85%;
        min-width: 85%;
    }
    .lowmakerdivtbaledivmain {
        width: 100%;
        max-width: 85%;
        min-width: 85%;
    }
}

@media(max-width:576px){
    .policytbaledivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .lowmakerdivtbaledivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .governencepolicytableheadtd {
        font: 700 15px / 17px var(--heading--font);
    }
    
    .governencepolicytableheadtd:nth-child(1){
        padding-left: 0px;
    }
    .governencepolicytableheadtd{
        padding-left: 30px;
    }
    .plicymanagemntdatatbleheadtdd:nth-child(1) {
        padding-left: 0px;
    }
    .plicymanagemntdatatbleheadtdd{
        padding-left: 30px;
    }
}