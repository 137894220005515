.runsimulatorbtn{
    background-color: #1fb4d2;
    color: #fff;
    font: 400 14px/16px var(--para--font);
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
}
.typetosetypeterchbtnrchdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
}

.policyproviderisktableheadtd {
    font: 700 22px / 28px var(--heading--font);
    color: #0e2537;
    padding-bottom: 20px;
    width: 100%;
    max-width: 30%;
}
.policyproviderisktableheadtd:nth-child(2) {
    text-align: center;
}
.policyproviderisktableheadtd:nth-child(3) {
    text-align: center;
}
.policyproviderpolicytablediv{
    width: 100%;
    max-width: 55%;
    min-width: 55%;
    border-collapse: collapse;
}

.policyprovideerriskdatatablebodytd {
    font: 400 16px / 20px var(--para--font);
    color: #0e2537;
    padding-bottom: 6px;
}
.policyprovideerriskdatatablebodytd:nth-child(2) {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}

.policyprovideerriskdatatablebodytd:nth-child(3) {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}
.techproviderdigitalcourttableheadtd:nth-child(5){
    text-align: center;
}
.techproviderdigitalcourtdatatablebodytd:nth-child(5){
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}

.plicymanagemntdatatbleheadtdd {
    font: 400 16px / 20px var(--para--font);
    color: #0e2537;
    padding-bottom: 6px;
}
.plicymanagemntdatatbleheadtdd:nth-child(2){
    text-align: center;
}

.plicymanagemntdatatbleheadtdd:nth-child(3){
    text-align: center;
}

.plicymanagemntdatatbleheadtdd:nth-child(4){
    text-align: center;
    text-decoration: underline;
}


.plicymanagemntdatatbleheadtdd:nth-child(5){
    text-align: left;
}
.browseimputdtfilediv{
    border: 1px solid #1fb4d2;
    padding: 5px 10px;
    width: 100%;
    max-width: 40%;
    margin: auto;
    border-radius: 5px;
    position: relative;
    color: #1fb4d2;
    font: 400 15px/17px var(--para--font);
}
.browseimputdtfiledivinpt{
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    opacity: 0;
}
@media(max-width:1199px){
.policyproviderpolicytablediv {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
    border-collapse: collapse;
}
}
@media(max-width:991px){
    .policyproviderpolicytablediv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border-collapse: collapse;
    }
}
@media(max-width:767px){
    .browseimputdtfilediv {
        width: 100%;
        max-width: 50%;
    }
    .plicymanagemntdatatbleheadtdd:nth-child(5) {
        text-align: left;
        padding-left: 20px;
    }
    .techproviderdigitalcourttableheadtd:nth-child(3) {
        padding-left: 20px;
    }
    .plicymanagemntdatatbleheadtdd:nth-child(4) {
        text-align: center;
        text-decoration: underline;
        padding-left: 60px;
    }
    .techproviderdigitalcourttableheadtd:nth-child(5) {
        padding-left: 40px;
    }
    .techproviderdigitalcourtdatatablebodytd:nth-child(3) {
     
        padding-left: 21px;
   
    }
    .policyproviderisktableheadtd {
        font: 700 20px / 22px var(--heading--font);
        padding-bottom: 10px;
    }

}
@media(max-width:576px){
    .typetosetypeterchbtnrchdivflx{
        flex-wrap: wrap;
    }
    .runsimulatorbtn{
        margin-bottom: 10px;
    }
    .browseimputdtfilediv {
        width: 100%;
        max-width: 65%;
    }
    .policyproviderpolicytablediv {
       white-space: nowrap;
    }
    .policyproviderisktableheadtd {
        font: 700 18px / 22px var(--heading--font);
        padding-bottom: 10px;
    }
}
@media(max-width:480px){
    .policyproviderisktableheadtd{
        padding-left: 30px;
    }
    .policyproviderisktableheadtd:nth-child(1){
        padding-left: 0px;
   }
}
