.compliencetabletbaledivmain{
    width: 100%;
    max-width: 30%;
    min-width: 30%;

}
.compliencelowmakerdivtbaledivmain{
    width: 100%;
    max-width: 30%;
    min-width: 30%; 
}
.compliencelowmakerdivtbaledivmain{
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}
.compliencepolicyandlowmakertablediv {
    display: flex;
    justify-content: flex-start;
    column-gap: 16px;
}

@media(max-width:1399px){
    .compliencepolicyandlowmakertablediv {
        display: flex;
        justify-content: flex-start;
        column-gap: 9px;
    }
}
@media (max-width: 1199px) {
    .compliencepolicyandlowmakertablediv {
        display: flex;
        justify-content: flex-start;
        column-gap: 4px;
    }
}


@media (max-width: 991px) {
    .compliencetabletbaledivmain{
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    
    }
    .compliencelowmakerdivtbaledivmain{
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }
    .compliencelowmakerdivtbaledivmain{
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }
    .compliencepolicyandlowmakertablediv{
        overflow-x: scroll;
    }
}

@media (max-width: 576px) {
    .compliencetabletbaledivmain{
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    
    }
    .compliencelowmakerdivtbaledivmain{
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }
    .compliencelowmakerdivtbaledivmain{
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }

}

@media (max-width: 420px) {
    .compliencetabletbaledivmain{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    
    }
    .compliencelowmakerdivtbaledivmain{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .compliencelowmakerdivtbaledivmain{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

}
