.globalriodkdatatablediv {
    width: 100%;
    max-width: 90%;
    min-width: 90%;
    border-collapse: collapse;
}

.globalriskdatatableheadtd {
    font: 700 20px / 25px var(--heading--font);
    color: #0e2537;
    text-align: left;
    padding-bottom: 20px;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.globalriskdatatableheadtd:nth-child(2) {
    text-align: center;
}

.globalriskdatatableheadtd:nth-child(3) {
    text-align: center;
}

.globalriskdatatableheadtd:nth-child(4) {
    text-align: left;
}

.globalriskdatadatatablebodytd {
    text-align: left;
    color: #0e2537;
    font: 400 18px / 30px var(--para--font);
    padding-bottom: 10px;
    text-decoration: none;
    width: 100%;
    /* max-width: 32%; */
    /* min-width: 32%; */
    /* white-space: pre-wrap; */
    /* word-wrap: break-word; */
    white-space: normal;
    word-break: break-all;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
    vertical-align: top;
}

.globalriskdatadatatablebodytd:nth-child(2) {
    text-align: center;
}

.globalriskdatadatatablebodytd:nth-child(3) {
    text-align: center;
}

.globalriskdatadatatablebodytd:nth-child(4) {
    text-align: left;
}

@media(max-width:1199px) {
    .globalriskdatatableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }

    .globalriodkdatatablediv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border-collapse: collapse;
        white-space: nowrap;
    }
}
@media(max-width:991px){
    .globalriskdatatableheadtd:nth-child(2){
        padding-left: 40px;
    }
    .globalriskdatatableheadtd:nth-child(3){
        padding-left: 40px;
    }
    .globalriskdatatableheadtd:nth-child(4){
        padding-left: 40px;
    }
    .globalriskdatadatatablebodytd:nth-child(2) {
        text-align: center;
        padding-left: 40px;
    }
    .globalriskdatadatatablebodytd:nth-child(3) {
        text-align: center;
        padding-left: 40px;
    }
    .globalriskdatadatatablebodytd:nth-child(4) {
        text-align: center;
        padding-left: 40px;
    }
}

@media(max-width:767px) {
    .globalriodkdatatablediv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border-collapse: collapse;
        white-space: nowrap;
    }

    .tabledatallforaioffender {
        width: 100%;
        overflow-x: scroll;
    }
    .globalriskdatatableheadtd {
        font: 700 15px / 17px var(--heading--font);
    }
    .globalriskdatadatatablebodytd {
        font: 400 15px / 18px var(--para--font);
    }
}