.techprovidermainsection {
    width: 100%;
    height: auto;
    padding: 40px 0px;
}

.techproviderwelcomecontent {
    width: 100%;
    padding-bottom: 20px;
}

.hellosaoifetxtpppmain {
    font: 600 40px/45px var(--heading--font);
    text-align: center;
    color: #0e2537;
}

.hellosaoifetxtpppmain span {
    font: 800 60px/65px var(--heading--font);
    color: #0e2537;
}

.techproivedrlogintxtp {
    color: red;
    font: 500 30px/35px var(--heading--font);
    text-align: center;
}

.clckheretxtcntntxtpp {
    text-align: center;
    color: #7c7979;
    font: 400 18px/22px var(--para--font);

}

.clckheretxtcntntxtppandbtn {
    padding: 30px 0px;
}

.allglbrlmbynmainspcl {
    border: none;
    background-color: #1fb4d2;
    padding: 7px 20px;
    color: #000;
    font: 400 18px/22px var(--para--font);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.fourglobaldivbrnmainflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}

.fourglobaldivbrmain {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
    cursor: pointer;
    text-decoration: none;
}

.fourglobaldivbrmainbrdr {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #0e2537;
    box-shadow: 5px 0px 3px 0px #0e2537;
    padding: 20px;
}

.fourglobtnmaindivkkkk {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fourglobaldivheadtrxtpp {
    text-align: center;
    font: 600 18px / 25px var(--para--font);
    color: #0e2537;
    text-decoration: none;
}

.fourglobaldivheadtrxratingtxtp {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
    font: 400 25px / 30px var(--para--font);
    margin: 10px 0px;
}

.globaldefenseimgmainfortch {
    position: relative;
    z-index: 9;
    transform: translateY(50%);
    right: -5px;
    top: -2px;
}

.globaldefenseimgmainglbl {
    width: 100%;
    max-width: 194px;
    min-width: 194px;
    height: 194px;
}

.globaldefenseimgmainglbl img {
    width: 100%;
    height: 100%;
}

.selectvalutetxtppp {
    font: 400 25px/25px var(--para--font);
    color: #7c7979;
    margin-bottom: 20px;
    padding-left: 100px;
}

.techproviderpagesection {
    width: 100%;
    height: auto;
    padding: 40px 0px;
}

.globalheadtadledatamain {
    width: 100%;
    background-color: #1fb4d2;
    padding: 15px 15px;
}

.globalheadtadledatadivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.apiintegrationtxtpp {
    color: #000;
    font: 600 25px/30px var(--heading--font);
}

.dshbtnreturn {
    background-color: #fff;
    color: #1fb4d2;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    font: 400 15px/20px var(--para--font);
}

.apiintegrationdatatable {
    width: 100%;
    padding-top: 20px;
    max-width: 50%;
    min-width: 50%;
}

.apiintegrationdatatabledivmain {
    width: 100%;
}

.apiintegrationdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.apiintegrationdivleft {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.apiintegrationdiinpy {
    width: 100%;
    padding: 4px 15px;
    border: 1px solid #1fb4d2;
    border-radius: 5px;

}

.apiintegrationdiinpy:focus {
    border: 1px solid #1fb4d2;
    outline: 0;
}

.apiintegrationdivflx {
    margin-bottom: 10px;
}

.apiintegrationdivright {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.tsturltextpp {
    color: #7c7979;
    font: 400 18px/20px var(--para--font);
}

.cnntnbtnmain {
    background-color: #1fb4d2;
    color: #fff;
    font: 400 15px/18px var(--para--font);
    padding: 4px 10px;
    border: none;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
}

.typetoserchbtn {
    background-color: transparent;
    color: #1fb4d2;
    border: 1px solid #1fb4d2;
    padding: 4px 15px;
    font: 400 12px / 14px var(--para--font);
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.typetoserchbtn::placeholder {
    color: #1fb4d2;
}

.typetoserchbtn:focus {
    outline: 0;
    border: 1px solid #1fb4d2;
}

.techproviderriskpolicytablediv {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.techproviderrisktableheadtd {
    font: 700 22px / 28px var(--heading--font);
    color: #0e2537;
    padding-bottom: 20px;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.techproviderrisktableheadtd:last-child {
    text-align: center;
}

.techproviderdatatablebodytd {
    font: 400 16px / 20px var(--para--font);
    color: #0e2537;
    padding-bottom: 6px;
}

.techproviderdatatablebodytd:last-child {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}

.techproviderriskpolicytabledivmain {
    padding-top: 20px;
}


.techproviderdigitalcourttablediv {
    width: 100%;
    max-width: 80%;
    min-width: 80%;

}

.techproviderdigitalcourttableheadtd {
    font: 700 22px/28px var(--heading--font);
    color: #0e2537;
    padding-bottom: 20px;
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.techproviderdigitalcourttableheadtd:nth-child(2) {
    text-align: center;
}

.techproviderdigitalcourttableheadtd:nth-child(3) {
    text-align: center;
}

.techproviderdigitalcourttableheadtd:nth-child(4) {
    text-align: center;
}

.techproviderdigitalcourtdatatablebodytd {
    font: 400 16px / 20px var(--para--font);
    color: #0e2537;
    padding-bottom: 6px;
}

.techproviderdigitalcourtdatatablebodytd:nth-child(2) {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}

.techproviderdigitalcourtdatatablebodytd:nth-child(3) {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}

.techproviderdigitalcourtdatatablebodytd:nth-child(4) {
    text-align: center;
    color: #1fb4d2;
    text-decoration: underline;
}

.docmnegaerimportdiv {
    padding-top: 20px;
}

.documnetmanagerdivmaian {
    width: 100%;

}

.viewdocmntvalulktbtn {
    border: 1px solid #1fb4d2;
    color: #1fb4d2;
    padding: 4px 15px;
    font: 400 15px/20px var(--para--font);
    border-radius: 5px;
    background-color: transparent;
}

.SelfServicDocument {
    width: 100%;
    padding: 10px;
    color: #000;
    font: 400 17px/18px var(--para--font);
    margin: 20px 0px;
    text-align: center;
    background-color: #a6b3bd;
}

.documnetmanagerdivfrm {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
    margin: 0 auto;
}

.documentmangerdivfrmmain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}

.importDownloadbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}

.importbtn {
    background-color: #0e2537;
    color: #fff;
    font: 400 14px/16px var(--para--font);
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
}

.downloadbtn {
    background-color: #7c7979;
    color: #fff;
    font: 400 14px/16px var(--para--font);
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
}

.documentmangerdivlbltxtp {
    font: 500 16px/18px var(--heading--font);
    color: #000;
}

.documentmangerdivsclttdiv {
    width: 100%;
    max-width: 90%;
    min-width: 90%;

}

.documentmangerdchsefilediv {
    width: 100%;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    position: relative;
}

.chosefiledivimgmain input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosefilelstttxpp {
    font: 400 14px/16px var(--para--font);
    color: #000;
    border-right: 1px solid #000;
    padding: 9px;
}

.nofilechsmrttxppp {
    font: 400 14px/16px var(--para--font);
    color: #000;
}

.documentmangerdivscltslct {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.documentmangerdivlbltxtp {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.techdigitalrecomendationtableheadtd {
    font: 700 22px / 28px var(--heading--font);
    color: #0e2537;
    padding-bottom: 20px;
}

.techdigitalrecomendationdatatablebodytd {
    font: 400 16px / 20px var(--para--font);
    color: #0e2537;
    padding-bottom: 6px;
}

@media(max-width:1499px) {
    .fourglobaldivheadtrxtpp {
        text-align: center;
        font: 600 17px / 22px var(--para--font);
        color: #0e2537;
    }

    .fourglobaldivbrmain {
        width: 100%;
        max-width: 31%;
        min-width: 31%;
    }

    .fourglobaldivbrmainbrdr {

        padding: 15px;
    }

    .globaldefenseimgmainfortch {
        position: relative;
        z-index: 9;
        transform: translateY(50%);
        right: -5px;
        top: -22px;
    }
    .apiintegrationdivleft {
        width: 100%;
        max-width: 25%;
        min-width: 25%;
    }

    

}

@media (max-width: 1399px) {
    .fourglobaldivheadtrxtpp {
        text-align: center;
        font: 600 16px / 20px var(--para--font);
        color: #0e2537;
    }
}

@media(max-width:1199px) {
    .techproviderriskpolicytablediv {
        width: 100%;
        max-width: 60%;
        min-width: 60%;
    }
    .techproviderdigitalcourttablediv{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .apiintegrationdivright {
        width: 100%;
        max-width: 68%;
        min-width: 68%;
    }
    .apiintegrationdivleft {
        width: 100%;
        max-width: 30%;
        min-width: 30%;
    }
    .tsturltextpp {
        color: #7c7979;
        font: 400 16px / 18px var(--para--font);
    }
    .apiintegrationdatatable {
        max-width: 60%;
        min-width: 60%;
    }
  
}

@media(max-width:991px) {
    .fourglobaldivbrnmainflx {
        width: 100%;
        max-width: 75%;
        min-width: 75%;
    }

    .selectvalutetxtppp {
        font: 400 20px / 25px var(--para--font);
        padding-left: 54px;

    }
    .apiintegrationdatatable {
        max-width: 70%;
        min-width: 70%;
    }

    .hellosaoifetxtpppmain {
        font: 600 30px / 35px var(--heading--font);
    }

    .techprovidermainsection {
        padding: 30px 0px;
    }

    .hellosaoifetxtpppmain span {
        font: 800 50px / 55px var(--heading--font);
    }

    .fourglobaldivbrmain {
        width: 100%;
        max-width: 45%;
        min-width: 45%;
    }

    .globaldefenseimgmainfortch {
        top: 42px;
    }

    .techproviderriskpolicytablediv {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }

    .typetoserchbtn {

        width: 100%;
        max-width: 50%;
        min-width: 50%;
    }

    .techproviderpagesection {
        width: 100%;
        height: auto;
        padding: 30px 0px;
    }
}

@media (max-width: 767px) {
    .fourglobaldivbrmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .fourglobtnmaindivkkkk {
        flex-wrap: wrap;
        justify-content: center;
    }
    .techproviderdigitalcourttableheadtd {
        font: 700 20px / 22px var(--heading--font);
    }

    .globaldefenseimgmainfortch {
        top: -30px;
    }

    .hellosaoifetxtpppmain {
        font: 600 20px / 25px var(--heading--font);
    }

    .hellosaoifetxtpppmain span {
        font: 800 40px / 45px var(--heading--font);
    }

    .techproivedrlogintxtp {
        font: 500 25px / 30px var(--heading--font);
    }

    .techproviderriskpolicytablediv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .apiintegrationtxtpp {
        color: #000;
        font: 600 20px / 25px var(--heading--font);
    }

    .techproviderrisktableheadtd {
        font: 700 20px / 22px var(--heading--font);
        padding-bottom: 10px;
    }
    .techproviderdigitalcourttableheadtd {
        font: 700 20px / 22px var(--heading--font);
        padding-bottom: 10px;
    }

    .documnetmanagerdivfrm {
        width: 100%;
        max-width: 90%;
        min-width: 90%;
        margin: 0 auto;
    }

    .techdigitalrecomendationtableheadtd {
        font: 700 20px / 22px var(--heading--font);
    }
    .techproviderdigitalcourttablediv{
        white-space: nowrap;
    }
    .techproviderriskpolicytabledivmain{
        overflow-x: scroll;
    }
    .techproviderdigitalcourttableheadtd:nth-child(2){
        padding-left: 40px;
        padding-right: 40px;
    }
   
    .techproviderdigitalcourttableheadtd:nth-child(4){
        padding-left: 60px;
    }
    .techproviderdigitalcourtdatatablebodytd:nth-child(4){
        padding-left: 60px;
    } 
}

@media(max-width:576px) {
    .clckheretxtcntntxtppandbtn {
        padding: 15px 0px;
    }

    .techprovidermainsection {
        padding: 20px 0px;
    }

    .apiintegrationtxtpp {
        color: #000;
        font: 600 18px / 20px var(--heading--font);

    }
    .techproviderdigitalcourttableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }
    .techproviderdigitalcourttableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }
    .apiintegrationdatatable {
        max-width: 100%;
        min-width: 100%;
    }

    .apiintegrationdivflx {
        column-gap: 10px;
    }


    .typetoserchbtn {

        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .techproviderrisktableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }

    .techproviderriskpolicytabledivmain {
        overflow-x: scroll;
    }

    .techproviderriskpolicytablediv {
        width: 100%;
        white-space: nowrap;
    }

    .techproviderpagesection {
        width: 100%;
        height: auto;
        padding: 20px 0px;
    }

    .techdigitalrecomendationtableheadtd {
        font: 700 18px / 22px var(--heading--font);
    }

    .techdigitalrecomendationtableheadtd:nth-child(2) {
        padding-left: 40px;
    }

    .techdigitalrecomendationdatatablebodytd:nth-child(2) {
        padding-left: 40px;
    }
}

@media(max-width:480px) {

    .apiintegrationtxtpp {
       
        font: 600 16px / 17px var(--heading--font);
    }
    .globalheadtadledatadivflx {
        align-items: baseline;
        justify-content: space-between;
    }
    .globalheadtadledatamain {
        padding: 11px 9px;
    }
    .dshbtnreturn {
        padding: 3px 8px;
        font: 400 12px / 20px var(--para--font);
    }
    .apiintegrationdivflx {
       flex-wrap: wrap;
    }
    .apiintegrationdivleft {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-bottom: 6px;
    }
    .apiintegrationdivright {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-bottom: 10px;
    }
}

@media(max-width:420px) {
    .importDownloadbtn {
        flex-wrap: wrap;
        align-items: baseline;
    }
    .apiintegrationtxtpp {
        font: 600 13px / 15px var(--heading--font);
    }

    .importbtn {
        margin-bottom: 10px;
    }
    .documentmangerdivsclttdiv {
        width: 100%;
        max-width: 86%;
        min-width: 86%;
    }
    .documnetmanagerdivfrm {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0 auto;
    }
}