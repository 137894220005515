.landingpagemain {
    width: 100%;
    height: auto;
    padding: 40px 0px;
}

.userformmajhafgloadduvflx {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.userformmdivright {
    width: 100%;
    max-width: 50%;
    min-width: 50%
}

.userformmdivleftt {
    width: 100%;
    max-width: 30%;
    min-width: 30%
}

.usenammeiinndivghdsjk {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding-bottom: 20px;
}

.uurstglkanbdiuixpp {
    font: 400 18px/20px var(--para--font);
    color: #0e2537;
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.inpiuuhsttlamnmdibttxt {
    width: 100%;
    max-width: 60%;
    min-width: 60%;

}

.inpiuuhsttlamnmdibttxtinpt {
    width: 100%;
    max-width: 100%;
    border: 1px solid #0e2537;
    color: #000;
    border-radius: 5px;
    padding: 6px 15px;


}
.inpiuuhsttlamnmdibttxtinpt:focus{
    border: 1px solid #0e2537;
    outline: 0;
}
.userformmdivglobaldefense{
    width: 100%;
    max-width: 100%;
    height: 427px;
}
.userformmdivglobaldefense img{
    width: 100%;
    height: 100%;
}
.userformmdivglobaldefenseabstlt{
    position: relative;
    top: 69px;
    /* left: auto; */
    right: 0;
    z-index: 66;
}
.Submitbythhhsmamm{
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #1fb4d2;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Submitbythhhsmammmain{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:1399px){
    .userformmdivleftt {
        width: 100%;
        max-width: 34%;
        min-width: 34%;
    }
}
@media(max-width:1199px){
    .userformmdivleftt {
        width: 100%;
        max-width: 30%;
        min-width: 30%;
    }
    .userformmdivglobaldefense {
        width: 100%;
        max-width: 100%;
        height: 318px;
    }
}

@media(max-width:991px){
    .userformmdivleftt {
        width: 100%;
        max-width: 28%;
        min-width: 28%;
    }
    .userformmdivglobaldefense {
        width: 100%;
        max-width: 100%;
        height: 266px;
    }
    .userformmdivglobaldefenseabstlt {
        position: relative;
        top: 61px;
        /* left: auto; */
        right: 0;
        z-index: 66;
    }
    .uurstglkanbdiuixpp {
        font: 400 18px / 20px var(--para--font);
        color: #0e2537;
        width: 100%;
        max-width: 22%;
        min-width: 22%;
    }
}

@media(max-width:767px){
    .userformmdivright {
        width: 100%;
        max-width: 60%;
        min-width: 60%;
    }
    .userformmdivleftt {
        width: 100%;
        max-width: 35%;
        min-width: 35%;
    }
}

@media(max-width:576px){
    .userformmajhafgloadduvflx{
      flex-wrap: wrap;
    }
    .userformmdivright {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .userformmdivleftt {
        width: 100%;
        max-width: 50%;
        min-width: 50%;
    }
    .userformmdivglobaldefenseabstlt {
        position: relative;
        top: 61px;
        /* left: auto; */
        right: 50%;
        z-index: 66;
    }
    .userformmdivglobaldefense {
        width: 100%;
        max-width: 100%;
        height: 270px;
    }
    .usenammeiinndivghdsjk {
        display: flex;
        justify-content: center;

    }
}
@media (max-width: 480px) {
    .userformmdivglobaldefense {
        width: 100%;
        max-width: 100%;
        height: 226px;
    }
}
@media (max-width: 420px) {
    .userformmdivglobaldefense {
        width: 100%;
        max-width: 100%;
        height: 248px;
    }
    .userformmdivleftt {
        width: 100%;
        max-width: 60%;
        min-width: 60%;
        margin: auto;
    }
    .userformmdivglobaldefenseabstlt {
        position: relative;
        top: 61px;
        /* left: auto; */
        left: 50%;
        transform: translateX(-50%);
        z-index: 66;
    }
    .offwnderbtn {
        font: 400 12px / 18px var(--para--font);
        padding: 4px 6px;
    }
    .allnewlinkdivli {
        margin: 0 10px 0px 10px;
    }
    .headerspcbtnli {
        margin: 0 6px 0px 6px;
        list-style-type: none;
        position: relative;
    }
    .allnewlinkdivli {
        margin: 0 6px 0px 6px;
    }
    .borderrightcustom {
        right: -5px;
    }
}

@media (max-width: 380px) {
   
    .userformmdivleftt {
        width: 100%;
        max-width: 70%;
        min-width: 70%;
        margin: auto;
    }
   
}